import axios from 'axios';

export interface iApiBasicResponse {
    statusCode: number
    message?: string
    validation_errors?: {
        [key: string]: string | Array<string>
    }
}
export interface iMultilevelStringOrNumber {
    [key: string]: undefined | null | string | string[] | number | number[] | boolean | boolean[] | iMultilevelStringOrNumber | iMultilevelStringOrNumber[]
}

export interface iListingQuery extends iMultilevelStringOrNumber {
    page?: number
    per_page?: number
    sort_order?: string
    sort_direction?: 'asc' | 'desc' | null
    keyword?: string
}

export interface iCollectionElement {
    uid: string
    created_at?: string
    updated_at?: string,
    submitted_at?: string,
}

export interface iScreenshot extends iCollectionElement {

    user_uid: string,
    mouse_moved: boolean,
    key_pressed: boolean,
    image_path: string,
    countable_duration: number,
    full_url: string
}

export interface iUser extends iCollectionElement {
    user_id: number;
    email: string;
    email_verified_at: string;
    name: string;
    first_name: string;
}

export interface iBasicListingResponse<T> extends iApiBasicResponse {
    current_page: number
    data: Array<T>
    length: number
    first_page_url: string
    from: number
    last_page: number
    last_page_url: string
    next_page_url: string
    path: string
    per_page: number
    to: number
    total: number,

}

export default class BaseAPIs {

    static hasError = (response: iApiBasicResponse) => {

        if (!response || response.statusCode >= 400) return true;
        if (!response || response.validation_errors) return true;
    };

    post = async (url: string, fd?: any): Promise<any> => {
        let data: any = {};
        data = {...fd}

        return axios
            .post(this.getApiBaseURL() + url, data, {
                headers: {"Content-Type": "application/json"},
                withCredentials: true,
            })
            .then((res): any => ({statusCode: res.status, ...res.data}))
            .catch((error): any => {
                if (error && error.response && error.response.status && error.response.data) {
                    return {
                        message: "Network Error", ...error.response.data, statusCode: error.response.status
                    }
                } else {
                    return {statusCode: 999, message: "Network Error"};
                }
            })
    };

    get = async (url: string, params?: any, abortController?: AbortController): Promise<any> => {
        return axios
            .get(this.getApiBaseURL() + url, {
                headers: {"Content-Type": "application/json"},
                withCredentials: true,
                params: params,
                signal: abortController && abortController.signal
            })
            .then((res): any => ({statusCode: res.status, ...res.data}))
            .catch((error): any => {
                if (error && error.response && error.response.status && error.response.data) {
                    return {
                        message: "Network Error", ...error.response.data, statusCode: error.response.status
                    }
                }
                if (error && error.response && error.response.status) {
                    if (error.response.status === 404) {
                        return {
                            message: "404 not found", statusCode: error.response.status
                        }
                    }
                    return {
                        message: "Network Error", statusCode: error.response.status
                    }
                } else {
                    return {statusCode: 999, message: "Network Error"};
                }
            })
    };
    download = async (url: string, download_name: string, params?: any,): Promise<any> => {
        return axios
            .get(this.getApiBaseURL() + url, {
                headers: {"Content-Type": "application/json"},
                withCredentials: true,
                params: params,
                cancelToken: params && params.cancel_token_source && params.cancel_token_source.token,
                responseType: "blob"
            })
            .then((res: any): any => {
                //fileDownload(res.data, download_name);
            })
            .catch((error): any => {

            })
    };


    getApiBaseURL = () => {
        const DEBUG = process.env.NODE_ENV !== 'production';
        return DEBUG ? "https://em.dev4.volobot.com" : (window.location.protocol + "//" + window.location.hostname + ":" + window.location.port);
    };

}
