import React, {useCallback, useEffect, useState} from 'react';
import './App.css';
import BaseAPIs from "./apis/base.apis";
import {Container} from "react-bootstrap";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Login from "./Pages/Login";
import Dashboard from "./Pages/Dashboard/Dashboard";

export const AppStateContext = React.createContext<any>({});

function App() {
    const [authState, setAuthState] = useState<{
        loading: boolean,
        user?: any
    }>({
        loading: true,
    });
    const onFetchUser = useCallback(() => {
        setAuthState({
            loading: true
        });
        new BaseAPIs().get("/_api/v1/user/get-user")
            .then((response) => {
                if (response.user) {
                    setAuthState({
                        ...authState,
                        user:response.user,
                        loading: false
                    })
                } else {
                    setAuthState({
                        ...authState,

                        loading: false
                    })
                }
            });
    }, [authState]);

    useEffect(() => {
        onFetchUser()
    }, [])

    if (authState.loading) return <div>Loading..</div>
    return (
        <BrowserRouter>
            <AppStateContext.Provider value={{
                authState:authState,
                setAuthState:setAuthState
            }}>
                <Container className="App" fluid>
                    <Routes>
                        <Route path="/dashboard" element={<Dashboard/>}/>
                        <Route path="/login" element={<Login/>}/>
                        <Route path="*" element={<Navigate to="/login"/>}/>
                    </Routes>
                </Container>
            </AppStateContext.Provider>
        </BrowserRouter>
    );
}

export default App;
