import React from "react";
import {iScreenshot} from "../../apis/base.apis";
import dayjs from "dayjs";
import usePaginatedResource from "../../components/hooks/useScrollPaginatedList";

export default function ScreenShots({user_id}: { user_id: string }) {
    const [{loading, soft_loading, error, list, result}, loadResource, {
        nextPage,
        changePerPage,
        changeKeyword,
        changeSort
    }] = usePaginatedResource<iScreenshot>(
        `/_api/v1/user/users/${user_id}/screenshots`,
        {loading: true, resource_key: "screenshots",query:{per_page:100}});
    const screenshots = list;

    const total_page = (result && result.last_page) || 1;
    const current_page = (result && result.current_page) || 1;
    if (loading) return <div>Loading...</div>;
    return <div>
        <div className="screen-shot__container">
            {
                screenshots && screenshots.map(value => {
                    return <div className="screen-shot__item" key={value.uid}>
                        <img src={value.full_url} width={300}  alt=""/>
                        <div>
                            <p>
                                AT: {dayjs(value.created_at).format("MMMM D, YYYY h:mm A")}
                            </p>
                            <p>
                                Active: {value.mouse_moved ? "Yes" : "NO"}
                            </p>
                        </div>
                    </div>
                })
            }

        </div>
        {
            !soft_loading && (total_page > current_page) && <a onClick={() => {
                nextPage()
            }} className="link">
                    <span>
                        Load more
                    </span>
            </a>
        }
        {
            soft_loading && <span>
                        Loading more...
                    </span>
        }
    </div>
}
