import React, {useCallback, useEffect, useState} from "react";
import useIsMounted from "ismounted";
import BaseAPIs, {iListingQuery} from "../../apis/base.apis";

export interface iResourceState<T> {
    loading: boolean,
    soft_loading: boolean,
    error: string,
    query: iListingQuery,
    skip_resource: boolean,
    result?: T
}

export default function useResource<T>(endpoint: string, options: {
    loading?: boolean,
    query?: iListingQuery,
    skip_resource?: boolean,
    method?: string,
}): [iResourceState<T>,
    (abortController?: AbortController,onComplete?:any) => void,
    {
        changeQuery: (query: iListingQuery, skip_resource?: boolean) => void,
        changePage: (page: number) => void,
        changePerPage: (per_page: number) => void,
        changeKeyword: (keyword: string) => void,
        changeSort: (sort_order: string, sort_direction: 'asc' | 'desc' | null | undefined) => void,
        changeSoftLoading: (soft_loading:boolean) => void,
    }
] {
    const [state, setState] = useState<iResourceState<T>>({
        loading: (options && options.loading) || false,
        soft_loading: (options && options.loading) || false,
        error: "",
        skip_resource: (options && options.skip_resource) || false,
        query: (options && options.query) || {},
    });
    const isMounted = useIsMounted();
    const loadResource = useCallback((abortController?: AbortController,onComplete?:any) => {
        setState({
            ...state,
            soft_loading:true
        })
        if (options.method === "POST") {

            new BaseAPIs().post(endpoint, state.query)
                .then(res => {
                    if(abortController && abortController.signal && abortController.signal.aborted)return;
                    if (BaseAPIs.hasError(res)) {
                        setState({
                            ...state,
                            error: res.message,
                            loading: false,
                            soft_loading:false
                        })
                    } else {
                        setState({
                            ...state,
                            error: "",
                            loading: false,
                            soft_loading:false,
                            result: res
                        })
                    }
                })
        } else {
            new BaseAPIs().get(endpoint, state.query, abortController)
                .then(res => {
                    if(abortController && abortController.signal && abortController.signal.aborted)return;
                    onComplete && onComplete()
                    if (BaseAPIs.hasError(res)) {
                        setState({
                            ...state,
                            error: res.message,
                            loading: false,
                            soft_loading:false,
                        })
                    } else {

                        setState({
                            ...state,
                            error: "",
                            loading: false,

                            soft_loading:false,
                            result: res
                        })
                    }
                })
        }

    }, [state, isMounted])
    const changePage = useCallback((page: number) => {
        setState({
            ...state,
            soft_loading:true,
            query: {
                ...state.query,
                page: page + 1//
            }
        })
    }, [state])
    const changePerPage = useCallback((per_page: number) => {
        setState({
            ...state,
            soft_loading:true,
            query: {
                ...state.query,
                per_page: per_page
            }
        })
    }, [state])
    const changeKeyword = useCallback((keyword: string) => {
        setState({
            ...state,
            soft_loading:true,
            query: {
                ...state.query,
                keyword: keyword
            }
        })
    }, [state])
    const changeSort = useCallback((sort_order: string, sort_direction: 'asc' | 'desc' | null | undefined) => {
        setState({
            ...state,
            soft_loading:true,
            query: {
                ...state.query,
                sort_order: sort_order,
                sort_direction: sort_direction,
            }
        })
    }, [state])

    const changeQuery = useCallback((query: iListingQuery, skip_resource?: boolean) => {
        setState({
            ...state,
            soft_loading:true,
            skip_resource: skip_resource === undefined ? state.skip_resource : skip_resource,
            query: query
        })
    }, [state])
    const changeSoftLoading = useCallback((soft_loading: boolean) => {
        setState({
            ...state,
            soft_loading:soft_loading,
        })
    }, [state])
    useEffect(() => {
        const controller = new AbortController();
        console.log("State query",state.query);
        if (!state.skip_resource) {
            loadResource(controller);
        } else {
            setState({
                ...state,
                error: "",
                loading: false,
                result: undefined
            })
        }
        return () => {
            controller.abort()
        }
    }, [state.query,state.skip_resource])
    return [state, loadResource, {
        changePage: changePage,
        changePerPage: changePerPage,
        changeKeyword: changeKeyword,
        changeSort: changeSort,
        changeQuery: changeQuery,
        changeSoftLoading: changeSoftLoading,
    }]
}
