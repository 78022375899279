import qs from "qs";
import dayjs from "dayjs";

const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
export const getFlatKeys = (o: any, prefix = ''): string[] => {
    return Object.keys(o).flatMap(k => {
        const dot = prefix ? '.' : '';
        const new_k = isNaN(+k) ? `${dot}${k}` : `[${k}]`;
        const new_prefix = `${prefix}${new_k}`;
        return Object(o[k]) === o[k] ? getFlatKeys(o[k], new_prefix) : [new_prefix]
    });
}

export function getNestedValue(o: any, s: string) {
    try {
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, '');           // strip a leading dot
        let a = s.split('.');
        for (let i = 0, n = a.length; i < n; ++i) {
            let k = a[i];
            if (k !== undefined && o !== undefined) {
                if (k in o) {
                    o = o[k];
                } else {
                    return "";
                }
            } else {
                return "";
            }

        }
        return o;
    } catch (e) {
        return ""
    }

}

export function JSONTryParse(date: string) {
    try {
        return JSON.parse(date);
    } catch (e) {
        return null;
    }
}

export function getFormattedDate(date?: string) {
    if (date) {
        return dayjs(date).format('DD MMM YYYY')
    } else {
        return "-"
    }
}

export function getFormattedRelative(date?: string) {
    if (date) {
        // @ts-ignore
        return dayjs(date).fromNow()
    } else {
        return "-"
    }
}


export function handleEqualAndGreaterZeroChange(e: any, handleChange: any) {
    if (!e.target.value) return handleChange(e);
    if (e.target.value === "0") {
        handleChange(e);
    }
    if (e.target.value.match(/[1-9]\d{0,5}/)) {
        if (e.target.value >= 0) {
            handleChange(e);
        }
    }

}

export const handleNumberAndGreaterThan = (e: any, handleChange: any) => {
    if (!e.target.value) return handleChange(e)
    if (e.target.value === 0) {
        handleChange(e)
    }
    if (e.target.value) {
        let matches = e.target.value.match(/[1-9]\d{0,9}/);
        if (matches && matches[0] === matches["input"]) {
            if (parseInt(e.target.value) >= 1) {
                handleChange(e)
            }
        }
    }
}
export const handleNPositiveNumber = (e: any, handleChange: any) => {
    if (!e.target.value) return handleChange(e)
    if (e.target.value.toString() === "0") {
        handleChange(e)
    }
    if (e.target.value) {
        let matches = e.target.value.match(/[1-9]\d{0,9}/);
        if (matches && matches[0] === matches["input"]) {
            if (parseInt(e.target.value) >= 0) {
                handleChange(e)
            }
        }
    }
}
export const handlePositiveAndDecimalNumber = (e: any, handleChange: any) => {
    if (!e.target.value) return handleChange(e)
    if (e.target.value === "0") {
        handleChange(e)
    }
    let matches = e.target.value.match(/^[0-9]+\.?[0-9]*$/);
    if (matches && matches[0] === matches["input"]) {
        if (parseInt(e.target.value) >= 0) {
            handleChange(e)
        }
    }
}


export interface iColumnData<T> {
    row: T
}

export function getIndexNameCaps(index: number) {
    switch (index) {
        case 0:
            return "First";
        case 1:
            return "Second";
        case 2:
            return "Third";
        case 3:
            return "Fourth";
        case 4:
            return "Fifth";
        case 5:
            return "Sixth";
        case 6:
            return "Seventh";
        case 7:
            return "Eighth";
        case 8:
            return "Ninth";
        case 9:
            return "Tenth";
        case 10:
            return "Eleventh";
        case 11:
            return "Twelfth";
    }
    return null;
}

export function getTime() {
    return [
        {
            label: "12:00 am",
            value: "00:00",
            key: "00:00 am"
        }, {
            label: "12:30 am",
            value: "00:30",
            key: "00:30 am",


        }, {
            label: "01:00 am",
            value: "01:00",
            key: "01:00 am"
        }, {
            label: "01:30 am",
            value: "01:30",
            key: "01:30 am",

        }, {
            label: "02:00 am",
            value: "02:00",
            key: "02:00 am",
        }, {
            label: "02:30 am",
            value: "02:30",
            key: "02:30 am",
        }, {
            label: "03:00 am",
            value: "03:00",
            key: "03:00 am",
        }, {
            label: "03:30 am",
            value: "03:30",
            key: "03:30 am",
        }, {
            label: "04:00 am",
            value: "04:00",
            key: "04:00 am"
        }, {
            label: "04:30 am",
            value: "04:30",
            key: "04:30 am",

        }, {
            label: "05:00 am",
            value: "05:00",
            key: "05:00 am",

        }, {
            label: "05:30 am",
            value: "05:30",
            key: "05:30 am"
        }, {
            label: "06:00 am",
            value: "06:00",
            key: "06:00 am",

        }, {
            label: "06:30 am",
            value: "06:30",
            key: "06:30 am",

        }, {
            label: "07:00 am",
            value: "07:00",
            key: "07:00 am",

        }, {
            label: "07:30 am",
            value: "07:30",
            key: "07:30 am",

        }, {
            label: "08:00 am",
            value: "08:00",
            key: "08:00 am",

        }, {
            label: "08:30 am",
            value: "08:30",
            key: "08:30 am",

        }, {
            label: "09:00 am",
            value: "09:00",
            key: "09:00 am",

        },
        , {
            label: "09:30 am",
            value: "09:30",
            key: "09:30 am",

        },
        {
            label: "10:00 am",
            value: "10:00",
            key: "10:00 am",

        }, {
            label: "10:30 am",
            value: "10:30",
            key: "10:30 am",

        }, {
            label: "11:00 am",
            value: "11:00",
            key: "11:00 am",

        }, {
            label: "11:30 am",
            value: "11:30",
            key: "11:30 am",

        }, {
            label: "12:00 pm",
            value: "12:00",
            key: "12:00 pm",

        }, {
            label: "12:30 pm",
            value: "12:30",
            key: "12:30 pm"
        }, {
            label: "1:00 pm",
            value: "01:00",
            key: "01:00 pm"

        }, {
            label: "01:30 pm",
            value: "01:30",
            key: "01:30 pm",
        }, {
            label: "02:00 pm",
            value: "02:00",
            key: "02:00 pm",

        }, {
            label: "02:30 pm",
            value: "02:30",
            key: "02:30 pm"
        }, {
            label: "03:00 pm",
            value: "03:00",
            key: "03:00 pm"
        }, {
            label: "03:30 pm",
            value: "03:30",
            key: "03:30 pm"

        }, {
            label: "04:00 pm",
            value: "04:00",
            key: "04:00 pm",

        }, {
            label: "04:30 pm",
            value: "04:30",
            key: "04:30 pm",

        }, {
            label: "05:00 pm",
            value: "05:00",
            key: "05:00 pm",

        }, {
            label: "05:30 pm",
            value: "05:30",
            key: "05:30 pm",

        }, {
            label: "06:00 pm",
            value: "06:00",
            key: "06:00 pm",

        }, {
            label: "06:30 pm",
            value: "06:30",
            key: "06:30 pm"
        }, {
            label: "07:00 pm",
            value: "07:00",
            key: "07:00 pm",

        }, {
            label: "07:30 pm",
            value: "07:30",
            key: "07:30 pm",

        }, {
            label: "08:00 pm",
            value: "08:00",
            key: "08:00 pm",

        }, {
            label: "08:30 pm",
            value: "08:30",
            key: "08:30 pm",

        }, {
            label: "09:00 pm",
            value: "09:00",
            key: "09:00 pm",

        }, {
            label: "09:30 pm",
            value: "09:30",
            key: "09:30 pm",

        }, {
            label: "10:00 pm",
            value: "10:00",
            key: "10:00 pm",

        }, {
            label: "10:30 pm",
            value: "10:30",
            key: "10:30 pm",

        }, {
            label: "11:00 pm",
            value: "11:00",
            key: "11:00 pm",

        }, {
            label: "11:30 pm",
            value: "11:30",
            key: "11:30 pm",

        }
    ]
}

export const QueryParser1 = (location: any): any => {

    let search = location.search.replace("?", "");
    let parsed = qs.parse(search)
    if (parsed) {
        return parsed
    }
    return {};
}

export function handleContactNumberChange(e: any, handleChange: any) {
    let phone_no = /^[\ \d\-\+\(\)]*$/;
    if (e.target.value.match(phone_no)) {
        handleChange(e);
    }
}

export function getAccountType() {
    return [
        {
            label: "Savings",
            value: "savings"
        },
        {
            label: "Current",
            value: "current"
        }
    ]
}

export function getSupportTicketTypes() {
    return [
        {
            label: "Payment Related Issue",
            value: "Payment Related Issue"
        },
        {
            label: "Delivery Related Issue",
            value: "Delivery Related Issue"
        },
        {
            label: "Packaging Related Issue",
            value: "Packaging Related Issue"
        },
    ]
}

export const getPasswordContainUppercase = (password: string) => {
    for (let i = 0; i < password.length; i++) {
        let ch = password[i];
        if ((/[A-Z]/).test(ch)) {
            return true
        }
    }
    return false
}
export const getPasswordContainLowerCase = (password: string) => {
    for (let i = 0; i < password.length; i++) {
        let ch = password[i];
        if ((/[a-z]/).test(ch)) {
            return true
        }
    }
    return false
}
export const getPasswordContainNumber = (password: string) => {
    for (let i = 0; i < password.length; i++) {
        let ch = password[i];
        if ((/^\d+$/).test(ch)) {
            return true
        }
    }
    return false
}
export const getPasswordContainSpecial = (password: string) => {
    for (let i = 0; i < password.length; i++) {
        let ch = password[i];
        if ((/[@$!%*?&]/).test(ch)) {
            return true
        }
    }
    return false
}
export const getTwoCharFromString = (value: string) => {
    if (!value) return "--"
    if (value.length === 0) return "--"
    if (value.length === 1) return value.toUpperCase();
    if (value.length > 1) {
        if (value.includes(" ") && value.split(" ").length > 1) {
            let after = value.split(" ");
            let first_letter = after[0][0]
            let second_letter = after[1][0];
            return (first_letter + second_letter).toUpperCase()
        }
        return (value[0] + value[1]).toUpperCase()
    }
    return "--"

}

export function deliveryTypeNote(delivery_type?: string) {

    if (delivery_type === "local_qcart_delivery") {
        return {
            title: "Qkart Delivery",
            body: "Delivery will happen using Qkart"
        }
    }
    if (delivery_type === "local_pick_up") {
        return {
            title: "Self Pickup",
            body: ""
        }
    }
    return {
        title: "",
        body: ""
    }
}

export function orderStatus(status?: string) {
    if (status === "placed") {
        return "Placed"
    }
    if (status === "accepted") {
        return "Confirmed"
    }
    if (status === "ready_for_qkart_pickup") {
        return "Ready For Pickup"
    }
    if (status === "delivered_by_qkart") {
        return "Delivered"
    }
    if (status === "locally_picked_up") {
        return "Picked up"
    }
    if (status === "qkart_rider_assigned") {
        return "Rider Assigned"
    }
    if (status === "picked_up_by_qkart") {
        return "Picked up"
    }
    if (status === "ready_for_local_pickup") {
        return "Ready for Pickup"
    }
    return ""
}
