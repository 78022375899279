import React from "react";
import "./style.scss"
import DateRangePicker from "react-bootstrap-daterangepicker";
import 'bootstrap-daterangepicker/daterangepicker.css';
import dayjs from "dayjs";
import {Form, InputGroup} from "react-bootstrap";
import {BsCalendar3} from "react-icons/bs";

interface iProps {
    onDateRangeChange:any,
    filter_created_at_start:any,
    filter_created_at_end:any,

}

export default function DateRange({onDateRangeChange,filter_created_at_end,filter_created_at_start}: iProps) {

    // @ts-ignore
    return <DateRangePicker

        onApply={(event, picker) => {
            onDateRangeChange && picker.startDate && picker.endDate && onDateRangeChange(
                dayjs(picker.startDate).format("YYYY-MM-DD"),
                dayjs(picker.endDate).format("YYYY-MM-DD")
            )
        }}

    >
        <InputGroup className="date-range-wrapper">
            <Form.Control
                value={(filter_created_at_start && ((filter_created_at_start) || "") + " - " + ((filter_created_at_end) || "")) || ""}
                size={"sm"}
                placeholder="Select date range" className="date-range-input">
            </Form.Control>
            <InputGroup.Text className="date-range-icon"><BsCalendar3/></InputGroup.Text>
        </InputGroup>
    </DateRangePicker>
}
