import React, {useContext, useEffect, useState} from "react";
import {AppStateContext} from "../../App";
import useResource from "../../components/hooks/useResource";
import BaseAPIs, {iBasicListingResponse, iUser} from "../../apis/base.apis";
import ScreenShots from "./ScreenShots";
import Stats from "./Stats/Stats";
import {Button} from "react-bootstrap";

export default function Dashboard() {

    const {authState} = useContext(AppStateContext)
    useEffect(() => {
        if (authState && !authState.user) {
            window.location.href = "/login"
        }
    }, [])

    const [{loading, soft_loading, error, result}, loadResource, {
        changePage,
        changePerPage,
        changeKeyword,
        changeSort
    }] = useResource<{
        users: iBasicListingResponse<iUser>
    }>(
        "/_api/v1/user/users",
        {loading: true});
    const users = result && result.users;
    const [activeUser, setActive] = useState<iUser>();

    return <div className="dashboard">
        <Stats/>
        <Button onClick={() => {
            new BaseAPIs().post("/_api/v1/auth/logout",).then(res => {
                window.location.href = "/login"

            })
        }}>
            Logout
        </Button>
        <div className="user__list">
            {
                users && users.data && users.data.map(value => {
                    return <div key={value.uid} onClick={() => {
                        setActive(value)
                    }} className={`user__list__item ${value.name === (activeUser && activeUser.name) ? "active" : ""}`}>
                        {value.name}
                    </div>
                })
            }


        </div>
        <div className="screen-shoot__list">
            {
                activeUser && <ScreenShots key={activeUser.uid} user_id={activeUser.uid}/>
            }
        </div>


    </div>
}
