import {Formik} from "formik";
import React, {useContext, useEffect} from "react";

import * as yup from "yup";
import {Button, Col, Row, Spinner} from "react-bootstrap";
import BaseAPIs from "../apis/base.apis";
import {focusOnError, HandleErrors} from "../components/helper/form.helper";
import useIsMounted from "ismounted";
import FormGroup from "../components/FormGroup/CustomFormGroup";
import {AppStateContext} from "../App";

export default function Login() {
    const {authState}=useContext(AppStateContext)
    const isMounted = useIsMounted()
    useEffect(()=>{
        if(authState && authState.user){
            window.location.href="/dashboard"
        }
    },[])
    return <Formik
        initialValues={{
            email: "",
            password: "",
        }}
        onSubmit={(values: any, helpers) => {

            new BaseAPIs().post("/_api/v1/auth/login", values).then(res => {
                if (isMounted.current) {

                    if (BaseAPIs.hasError(res)) {
                        helpers.setSubmitting(false)
                        if (!HandleErrors(res, helpers)) {

                        }
                    } else {
                        window.location.href="/dashboard"
                    }
                }

            })

        }}
        validationSchema={yup.object({
            email: yup.string().email("Please enter valid email address").required("Please enter email ID"),
            password: yup.string().required("Please enter your password"),
        })}
        validateOnChange={false}
    >
        {({
              handleSubmit,
              handleChange,
              values,
              touched,
              isSubmitting,
              validateForm,
              errors,
              setFieldValue,
              handleBlur
          }: any) => {
            return <form onSubmit={handleSubmit}>
                <Row>
                    <FormGroup
                        formGroupProps={{as: Col, md: 12}}
                        label={"Email"}
                        name="email"
                        type="text"
                        onChange={handleChange}
                        touched={touched}
                        errors={errors}
                        maxLength={255}
                        values={values}/>

                    <FormGroup
                        formGroupProps={{as: Col, md: 12}}
                        label={"Password"}
                        name="password"
                        type="password"
                        onChange={handleChange}
                        touched={touched}
                        errors={errors}
                        maxLength={255}
                        values={values}/>

                    <Col md={12} className="mb-3">
                        <Button type="submit"
                                disabled={isSubmitting}
                                onClick={async () => focusOnError(await validateForm())}>
                            {isSubmitting &&
                            <Spinner animation="border" size="sm"/>}  &nbsp;
                            Login
                        </Button>

                    </Col>
                </Row>
            </form>
        }
        }</Formik>
}
