import React from "react";
import {Form, InputGroup} from "react-bootstrap";
import {getNestedValue} from "../helper/common";

interface Props {
    after_label_muted_text?: any
    formGroupProps: any,
    required?: boolean,
    label: string | React.ReactNode
    name: string
    type: string
    hidden?: boolean
    maxLength?: number
    onChange: (e: React.ChangeEvent<any>) => void,
    onBlur?: (e: React.FocusEvent<any>) => void,
    onWheel?: (e: any) => void,
    touched: any,
    errors: any,
    disabled?: boolean
    values: any,
    autoComplete?: string,
    as?: any,
    muted_text?: string,
    min?: number
    placeholder?: string,
    icon?: any
    fullWidth?: any
    input_props?:any
}


function FormGroup({
                       icon,
                       formGroupProps,
                       required,
                       hidden,
                       min,
                       onWheel,
                       placeholder,
                       label,
                       after_label_muted_text,
                       name,
                       type,
                       autoComplete,
                       disabled,
                       maxLength,
                       onChange,
                       onBlur,
                       touched,
                       errors,
                       values,
                       as,
                       muted_text,
                       fullWidth,
                       input_props,
                   }: Props) {
    const FormControl = <>
        <Form.Control
            type={type}
            autoComplete={autoComplete}
            maxLength={maxLength}
            disabled={disabled}
            name={name}
            as={as}
            hidden={hidden}
            placeholder={placeholder}
            onWheel={onWheel}
            min={min}
            style={{width: fullWidth ? "100%" : undefined}}
            value={getNestedValue(values, name)}
            onChange={onChange}
            onBlur={onBlur}
            isInvalid={getNestedValue(touched, name) && !!getNestedValue(errors, name)}
            {...input_props}
        />
        {
            muted_text && <Form.Text muted>
                {muted_text}
            </Form.Text>
        }
        {
            touched && !!errors && <Form.Control.Feedback type="invalid" >
                {getNestedValue(errors, name)}
            </Form.Control.Feedback>
        }
    </>


    return <Form.Group {...formGroupProps} className="mb-3">
        {
            !!label && <Form.Label>{label} {required && "*"}</Form.Label>
        }
        {
            after_label_muted_text && <Form.Text muted>
                {after_label_muted_text}
            </Form.Text>
        }
        {
            icon && <InputGroup>
                {icon}
                {FormControl}
            </InputGroup>
        }
        {!icon && FormControl
        }


    </Form.Group>
}

export default FormGroup;
