import React from "react";
import useResource from "../../../components/hooks/useResource";
import {iScreenshot} from "../../../apis/base.apis";
import DateRange from "../../../components/DateRange/DateRange";
import dayjs from "dayjs";
import {Table} from "react-bootstrap";

const duration = require("dayjs/plugin/duration")
dayjs.extend(duration)


export interface iAverageWakeTime {
    date: string,
    created_at: string
    updated_at: string
    countable_duration: number
}

export interface iStats {
    name: string,
    user_uid: string,
    duration: string,
    latest_active: iScreenshot,
    latest: iScreenshot
    average_wake_time: iAverageWakeTime[],

}

export default function Stats() {

    const [{loading, soft_loading, query, error, result}, loadResource, {
        changePage,
        changePerPage,
        changeKeyword,
        changeSort,
        changeQuery
    }] = useResource<{
        stats: iStats[]
    }>(
        "/_api/v1/user/stats",
        {
            loading: true, query: {
                start_date: dayjs().format("YYYY-MM-DD"),
                // @ts-ignore
                end_date: dayjs().add(dayjs.duration({'days': 1})).format("YYYY-MM-DD"),
            }
        });
    const stats = result && result.stats;
    return <div>
        <DateRange
            onDateRangeChange={(start_date: string, end_date: string) => {
                changeQuery({
                    start_date: start_date,
                    end_date: end_date,

                })
            }}
            filter_created_at_end={query.end_date}
            filter_created_at_start={query.start_date}
        />
        <div className="d-flex">

            {
                stats && stats.map(value => {
                    return <div key={value.name} className="w-50 p-2">
                        <h6>
                            {value.name}
                        </h6>
                        <p>
                            {value.duration}
                        </p>
                        {
                            value.latest_active && value.latest_active.created_at && <p>
                                Last active at: {dayjs(value.latest_active.created_at).format("MMMM D, YYYY h:mm A")}
                            </p>
                        }
                        {
                            value.latest && value.latest.created_at && <p>
                                Last screen updated
                                at: {dayjs(value.latest && value.latest.created_at).format("MMMM D, YYYY h:mm A")}
                            </p>
                        }
                        <img
                            alt={`Pixel report for ${value.name}`}
                            style={{width: "100%"}}
                            src={`https://em.dev4.volobot.com/_api/v1/user/pixels/${value.user_uid}`}/>
                        <div>
                            <Table>
                                <tbody>
                                {
                                    value.average_wake_time && value.average_wake_time.map(value1 => {
                                        return <tr>
                                            <td>
                                                {dayjs(value1.created_at).format("MMMM D, ddd")}
                                            </td>
                                            <td>
                                                {dayjs(value1.created_at).format("h:mm A")}
                                            </td>
                                            <td>
                                                {dayjs(value1.updated_at).format("h:mm A")}
                                            </td>
                                            <td>
                                                {
                                                    new Date(value1.countable_duration * 1000).toISOString().substring(11, 16)
                                                }

                                            </td>
                                        </tr>
                                    })
                                }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                })
            }
        </div>

    </div>
}
