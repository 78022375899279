import React, {useCallback, useEffect, useRef, useState} from "react";
import BaseAPIs, {iApiBasicResponse, iBasicListingResponse, iListingQuery} from "../../apis/base.apis";

export interface iResourceState<T> {
    loading: boolean,
    reload_on_focus: boolean,
    soft_loading: boolean,
    loading_more: boolean,
    error: string,
    query: iListingQuery,
    skip_resource: boolean,
    result?: iBasicListingResponse<T>,
    list: T[]
    resource_key: string
}

export interface iPaginationResponse<T> extends iApiBasicResponse {
    T: iBasicListingResponse<T>
}

export default function usePaginatedResource<T>(endpoint: string, options: {
    loading?: boolean,
    reload_on_focus?: boolean,
    query?: iListingQuery,
    skip_resource?: boolean,
    method?: string,
    resource_key?: string,
},): [
    iResourceState<T>,
    (abortController?: AbortController, onComplete?: any) => void,
    {
        changeQuery: (query: iListingQuery, skip_resource?: boolean) => void,
        nextPage: () => void,
        onRefresh: () => void,
        changePerPage: (per_page: number) => void,
        changeKeyword: (keyword: string) => void,
        changeSort: (sort_order: string, sort_direction: 'asc' | 'desc') => void,
    }
] {
    const [state, setState] = useState<iResourceState<T>>({
        loading: (options && options.loading) || false,
        reload_on_focus: (options && options.reload_on_focus) || false,
        soft_loading: false,
        loading_more: false,
        error: "",
        skip_resource: (options && options.skip_resource) || false,
        query: {per_page: 10, page: 1, ...((options && options.query) || {})},
        list: [],
        resource_key: (options && options.resource_key) || ""
    });


    const isMounted = useRef(false)
    const loadResource = useCallback((abortController?: AbortController, onComplete?: any) => {
        if (!state.loading_more) {
            setState({
                ...state,
                soft_loading: true
            })
        }
        new BaseAPIs().get(endpoint, state.query, abortController,)
            .then(res => {
                if (abortController && abortController.signal.aborted) {
                    return;
                }
                if (BaseAPIs.hasError(res)) {
                    setState({
                        ...state,
                        error: res.message,
                        loading: false,
                        soft_loading: false,
                        loading_more: false
                    })
                } else {
                    if (res && res[state.resource_key] && res[state.resource_key].current_page === 1) {
                        let list = (res && res[state.resource_key] && res[state.resource_key].data) || [];
                        setState({
                            ...state,
                            error: "",
                            loading: false,
                            soft_loading: false,
                            result: res[state.resource_key],
                            list: list,
                            loading_more: false
                        })
                    } else {
                        let list = (res && res[state.resource_key] && res[state.resource_key].data) || [];
                        setState({
                            ...state,
                            error: "",
                            loading: false,
                            soft_loading: false,
                            result: res[state.resource_key],
                            list: [...state.list, ...list],
                            loading_more: false
                        })
                    }
                }
                onComplete && onComplete()
            })


    }, [state, isMounted.current])
    const nextPage = useCallback(() => {
        if (state.soft_loading || state.loading_more) {
            return
        }
        if (state.result && state.query && state.query.page &&
            state.result.current_page &&
            state.result.current_page < state.query.page) {
            return;
        }
        if (state.result && state.result.current_page !== state.result.last_page) {
            setState({
                ...state,
                query: {
                    ...state.query,
                    page: ((state.query.page) || 1) + 1,

                },
                loading_more: true
            })
        }

    }, [state])
    const onRefresh = useCallback(() => {

        if (state.query && state.query.page && state.query.page > 1) {
            changeQuery({...state.query, page: 1})
        } else {
            loadResource()
        }
        loadResource()

    }, [state])
    const changePerPage = useCallback((per_page: number) => {

        setState({
            ...state,
            query: {
                ...state.query,
                per_page: per_page
            }
        })
    }, [state])
    const changeKeyword = useCallback((keyword: string) => {
        setState({
            ...state,
            query: {
                ...state.query,
                keyword: keyword
            }
        })
    }, [state])
    const changeSort = useCallback((sort_order: string, sort_direction: 'asc' | 'desc') => {
        setState({
            ...state,
            query: {
                ...state.query,
                sort_order: sort_order,
                sort_direction: sort_direction,
            }
        })
    }, [state])

    const changeQuery = useCallback((query: iListingQuery, skip_resource?: boolean) => {

        setState({
            ...state,
            skip_resource: skip_resource === undefined ? state.skip_resource : skip_resource,
            query: query
        })
    }, [state])
    useEffect(() => {
        const controller = new AbortController();
        isMounted.current = true
        if (!state.skip_resource) {
            loadResource(controller);
        } else {
            setState({
                ...state,
                error: "",
                loading: false,
                result: undefined
            })
        }

        return () => {
            isMounted.current = false
            controller.abort()
        }
    }, [state.query, state.skip_resource,])

    return [state, loadResource, {
        nextPage: nextPage,
        changePerPage: changePerPage,
        changeKeyword: changeKeyword,
        changeSort: changeSort,
        changeQuery: changeQuery,
        onRefresh: onRefresh,
    }]
}
